body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.seatName{
  margin-left: -30rem !important;
}
.text{
color:black;
text-decoration: none;
}


.arrows-3 .slick-next {
  right: 0;
}

.arrows-3 .slick-prev::after {
  left: 25px;
  content: url("./assets/imgs/left-arrow-fill.svg");
  width: 10px;
  height: 10px;
  display: table;
  position: relative;
  -webkit-transform: unset !important;
  -ms-transform: unset !important;
  transform: unset !important;
  border-top: none !important;
  border-left: none !important;
}

.arrows-3 .slick-next {
	right: 0;
  }

  .arrows-3 .slick-prev::after {
	left: 25px;
	content: url("./assets/imgs/left-arrow-fill.svg");
	width: 10px;
	height: 10px;
	display: table;
	position: relative;
	-webkit-transform: unset !important;
	-ms-transform: unset !important;
	transform: unset !important;
	border-top: none !important;
	border-left: none !important;
  }

  .slick-track-no-margin .slick-track {
	margin-right: 0 !important;
  }
  
  
  .movie-slider-arrows .slick-prev {
	left: -20px;
	right: unset;
	background: linear-gradient(rgb(71,129,255) ,rgb(103,88,229) ) !important;
  }
  
  .movie-slider-arrows .slick-prev:before,
  .movie-slider-arrows .slick-next:before,
  .arrows-3 .slick-next::after {
	left: 22px;
	content: url("./assets/imgs/right-arrow-fill.svg");
	width: 10px;
	height: 10px;
	display: table;
	position: relative;
	-webkit-transform: unset !important;
	-ms-transform: unset !important;
	transform: unset !important;
	border-top: none !important;
	border-left: none !important;
  }
  
  .movie-slider-arrows .slick-next:before,
.banner-arrows .slick-next:before {
  transform: rotate(180deg);
  margin-left: 6px;
}

.selected-date-card {
	background: linear-gradient(rgb(71, 129, 255), rgb(103, 88, 229)) !important;
	color: white !important;
  }
  
  .selected-date-card .day,
  .selected-date-card .month {
	color: #fff;
  }
  

  .show-date-active {
    background-color: rgb(71, 129, 255);
    color: #fff !important;
    border: 1.5px solid rgb(71, 129, 255);
  }

  .show-date-active .day {
    color: #ffffff !important;
  }


.movie-slider-arrows .slick-next {
  right: -20px;
  left: unset;
  /* background: url("../pictures/slick-arrows.svg") */
}
  .arrows-3 .slick-next:focus,
  .arrows-3 .slick-prev:focus {
	outline: 0;
  }
  
  .arrows-3 .slick-prev {
	left: -60px;
  }

.arrows-3 .slick-next::after {
  left: 22px;
  content: url("./assets/imgs/left-arrow-fill.svg");
  width: 10px;
  height: 10px;
  display: table;
  position: relative;
  -webkit-transform: unset !important;
  -ms-transform: unset !important;
  transform: unset !important;
  border-top: none !important;
  border-left: none !important;
}
.arrows-3 .slick-next:focus,
.arrows-3 .slick-prev:focus {
  outline: 0;
}
.arrows-3 .slick-prev {
  left: -60px;
}

.slick-track-no-margin .slick-track {
  margin-left: 0 !important;
}

.movie-slider-arrows .slick-next:before,
.banner-arrows .slick-next:before {
  transform: rotate(180deg);
  margin-left: 6px;
}

.movie-slider-arrows .slick-next {
  right: -20px;
  left: unset;
  /* background: url("../pictures/slick-arrows.svg") */
}

.movie-slider-arrows .slick-prev {
  left: -20px;
  right: unset;
  background: linear-gradient(rgb(71,129,255) ,rgb(103,88,229) ) !important;
}
